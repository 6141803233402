.botao-editar-tabela {
  margin: 5px;
  cursor: pointer;
  color: #145401;
}
.botoes-editar-tabela {
  text-align: right;
}

.grecaptcha-badge {
  visibility: hidden;
}
